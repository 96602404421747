<template>
  <Sidebar class="sidebar" :pose="isVisible ? 'visible': 'hidden'">
    <a class="main-nav-cta btn-cta" href="/request-a-quote/">Request a<br/> quote</a>
    <div class="menu-handle s_active" @click="toggleMenu()">
      <span></span> 
      <span></span> 
      <span></span>
    </div>
    <div class="sidebar-items">
      <!-- <Item class="main-nav-cta btn-cta">Request a<br/>free quote</Item> -->
      <Item class="item" v-for="(item,id) in items" v-bind:key="id" v-bind:href="item.url" v-html="item.title"></Item>
    </div>
  </Sidebar>
</template>

<script>
import 'core-js/modules/es.weak-set';
import posed from 'vue-pose';
import { EventBus } from '../event-bus.js';

export default {
  data() {
    return {
    isVisible: this.isVisibleProp,
    }
  },
  props: {
      isVisibleProp: Boolean,
      items: Array
  },
  watch: {
    isVisibleProp: function () {
      this.isVisible = this.isVisibleProp
    }
  },
  methods: {
    toggleMenu() {
      // isVisible = !isVisible;
      this.isVisible = !this.isVisible;
      EventBus.$emit('menu-toggle', this.isVisible);
    },
    toggleModal() {
      EventBus.$emit('menu-toggle', !this.isVisible);
      EventBus.$emit('modal-toggle', this.isVisible);
    }
  },
  components: {
    Sidebar: posed.div({
      visible: {
        x: 0,
        beforeChildren: true,
        staggerChildren: 30,
        transition: { type: 'tween' }
      },
      hidden: {
        x: '100%',
        // afterChildren: true
      }
    }),
    Item: posed.a({
      visible: { opacity: 1, y: 0 },
      hidden: { opacity: 0, y: 20 }
    })
  }
};
</script>

<style scoped>

.btn-cta {
  border-color: white;
  color: white;
  font-weight: 600;
  text-align:center;
}

</style>
