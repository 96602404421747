<template>
<div>

    <div class="form-response" :class="[{ 's_active' : isSuccess }, responseCustomClass]">
      <div class="form-response-aside">
        <svg class="icon"><use xlink:href="#check-circle"></use></svg>
      </div>
      <div class="form-response-main" v-html="successMessage"></div>
    </div>

  <form method="post" @submit="formSubmit" :id="`form_${formHandle}`" class="form-freeform" :class="[{ 's_loading' : isSubmitted }, formCustomClass]" novalidate="true" v-if="!isSuccess">

    <!-- loading -->
    <div class="loader">
      <svg class="circular-loader" viewBox="25 25 50 50" >
        <circle class="loader-path" cx="50" cy="50" r="20" fill="none" stroke="#ffffff" stroke-width="3" />
      </svg>
    </div>

    <input type="hidden" name="action" value="freeform/api/form">
    <input type="hidden" name="formHash" :value="formHash">
    <input type="hidden" name="CRAFT_CSRF_TOKEN" :value="formVars.csrf">

    <!-- honeypot -->
    <div aria-hidden="true" tabindex="-1" style="position: absolute !important; width: 0px !important; height: 0px !important; overflow: hidden !important;">
      <label aria-hidden="true" tabindex="-1" for="freeform_form_handle">
        Leave this field blank
      </label>
      <input
        type="text"
        :value="formHoneypot.hash"
        :name="formHoneypot.name"
        :id="formHoneypot.name"
        aria-hidden="true"
        tabindex="-1">
    </div>

    <!-- errors -->
    <div class="form-errors" v-if="formErrors.length || fieldErrors.length">
      <p class="has-error" v-for="error in formErrors" v-html="error"></p>
      <p class="has-error" v-for="(error, key) in fieldErrors" v-html="'[Field: ' + key + '] ' + error"></p>
    </div>

    <div :class="row.rowClass" v-for="row in formLayout">
      <div v-for="field in row.columns" :class="[field.groupClass, {'has-error' : $v.formFields[field.handle].$dirty && $v.formFields[field.handle].$error}, {'has-success' : $v.formFields[field.handle].$dirty && !$v.formFields[field.handle].$error}, {'s_active' : $v.formFields[field.handle].$model && $v.formFields[field.handle].$model !== null || focusField == field.handle}]" v-bind:key="field.id">

        <template v-if="(field.conditionalField.length && field.conditionalValue.length && ($v.formFields[field.conditionalField].$model == field.conditionalValue)) || (field.conditionalField.length && !field.conditionalValue.length && $v.formFields[field.conditionalField].$model.length) || !field.conditionalField.length">

          <template v-if="field.type == 'submit'">
            <div :class="field.controlClass">
              <template v-if="options.submitButton == 'arrow'">
                <button type="submit" class="form-btn">
                  <span v-html="field.label"></span>
                  <svg class="icon"><use xlink:href="#arrow-right"></use></svg>
                </button>
              </template>
              <template v-else>
                <input
                  type="submit"
                  name="submit"
                  class="btn"
                  :class="field.inputClass"
                  :value="field.label" />
              </template>
            </div>
          </template>

          <template v-else-if="field.type == 'mailing_list'">

          <template v-if="field.hidden">
            <input
              type="hidden"
              :name="field.hash"
              :id="field.hash"
              value="1">
          </template>

          <template v-else>
            <div :class="field.controlClass">

              <label :class="field.labelClass" :for="field.hash">
              <input
                type="checkbox"
                :name="field.hash"
                :id="field.hash"
                value="1"
                :required="field.required ? true : false"
                :class="field.inputClass"
                false-value=""
                v-model="formFields[field.hash]"
                @change="$v.formFields[field.handle].$touch()" />
                <span></span>
                {{ field.label }}
              </label>

              <template v-if="$v.formFields[field.hash]">
                <p v-if="$v.formFields[field.hash].$dirty && $v.formFields[field.hash].$error" class="has-error">
                  <span v-for="param in $v.formFields[field.hash].$params">
                    <span v-if="!$v.formFields[field.hash][param.type]">{{ errorMessages[param.type] }}</span>
                  </span>
                </p>
              </template>

            </div>
          </template>

        </template>

        <template v-else-if="field.type == 'hidden'">
            <input
              :type="field.type"
              :name="field.handle"
              :id="field.handle"
              :class="field.inputClass"
              value=""
              v-model="formFields[field.handle]"
              :placeholder="field.placeholder"
              :autocapitalize="field.inputAttr.autocapitalize"
              :autocorrect="field.inputAttr.autocorrect"
              :required="field.required ? true : false"
              @input="$v.formFields[field.handle].$touch()">
        </template>

        <template v-else-if="field.type == 'checkbox'">
            <div :class="field.controlClass">

              <div class="styledcheckbox">
                <input
                  type="checkbox"
                  :name="field.handle"
                  :id="field.handle"
                  :value="field.value"
                  :required="field.required ? true : false"
                  :class="field.inputClass"
                  false-value=""
                  v-model="formFields[field.handle]"
                  @change="$v.formFields[field.handle].$touch()" />
                <label :class="field.labelClass" :for="field.handle">
                  <span class="checkbox-value">{{ field.label }}</span>
                </label>
              </div>

              <template v-if="$v.formFields[field.handle]">
                <p v-if="$v.formFields[field.handle].$dirty && $v.formFields[field.handle].$error" class="has-error">
                  <span v-for="param in $v.formFields[field.handle].$params">
                    <span v-if="!$v.formFields[field.handle][param.type]">{{ errorMessages[param.type] }}</span>
                  </span>
                </p>
              </template>

            </div>
        </template>

          <template v-else-if="field.type == 'html'">
            <div :class="field.controlClass" v-html="field.value"></div>
          </template>

          <template v-else>

            <label :for="field.handle">
              {{ field.label }}
              <span class="help-block" v-if="field.instructions && !field.groupClass.includes('l_placeholder')" v-html="field.instructions"></span>
            </label>

            <div :class="field.controlClass">

              <template v-if="field.computedType == 'option_group'">
                <div :class="`${field.optionType}-${field.optionClass}`" v-for="(opt, index) in field.options">
                  <div :class="`styled${field.optionType}`">
                    <input
                      type="checkbox"
                      :name="field.name"
                      :id="`${field.name}${index}`"
                      :value="`${opt.value}`"
                      :class="field.inputClass"
                      v-model="formFields[field.handle]"
                      @change="$v.formFields[field.handle].$touch()"
                      v-if="field.optionType == 'checkbox'"
                    />
                    <input
                      type="radio"
                      :name="field.name"
                      :id="`${field.name}${index}`"
                      :value="`${opt.value}`"
                      :class="field.inputClass"
                      v-model="formFields[field.handle]"
                      @change="$v.formFields[field.handle].$touch()"
                      v-if="field.optionType == 'radio'"
                    />
                    <label
                      :class="`${field.optionType}-${field.optionClass}`"
                      :for="`${field.name}${index}`">
                      <span :class="`${field.optionType}-value`" v-html="opt.label"></span>
                    </label>
                  </div>
                </div>
              </template>

              <template v-else-if="field.type == 'select' || (field.type == 'dynamic_recipients' && !field.showAsRadio && !field.showAsCheckboxes)">
                <select
                  :name="field.handle"
                  :id="field.handle"
                  :required="field.required ? true : false"
                  :class="field.inputClass"
                  v-model="formFields[field.handle]"
                  @focus="handleFocus($v.formFields[field.handle], field.handle)"
                  @blur="handleBlur($v.formFields[field.handle])">
                  <option :value="``">Please select...</option>
                  <option v-for="opt in field.options" :value="opt.value">{{ opt.label }}</option>
                </select>
              </template>

              <template v-else-if="field.type == 'textarea'">
                <textarea
                  :name="field.handle"
                  :id="field.handle"
                  :required="field.required ? true : false"
                  :class="field.inputClass"
                  :rows="field.inputAttr.rows"
                  :data-min-rows="field.inputAttr.dataMinRows"
                  v-model="formFields[field.handle]"
                  @input="handleTouch($v.formFields[field.handle])"
                  @focus="handleFocus($v.formFields[field.handle], field.handle)"
                  @blur="handleBlur($v.formFields[field.handle])">
                </textarea>
              </template>

              <template v-else-if="field.type == 'file'">
                <input
                  :type="field.type"
                  :name="field.name"
                  :id="field.handle"
                  :class="field.inputClass"
                  v-model="formFields[field.handle]"
                  :placeholder="field.placeholder"
                  :autocapitalize="field.inputAttr.autocapitalize"
                  :autocorrect="field.inputAttr.autocorrect"
                  :pattern="field.inputAttr.pattern"
                  data-multiple-caption="{count} files selected"
                  multiple
                  :required="field.required ? true : false"
                  @input="handleTouch($v.formFields[field.handle])">
                </textarea>
                <label :for="field.handle">Choose a file</label>
              </template>

              <template v-else>
                <input
                  :type="field.type"
                  :name="field.handle"
                  :id="field.handle"
                  :class="field.inputClass"
                  v-model="formFields[field.handle]"
                  :placeholder="field.placeholder"
                  :autocapitalize="field.inputAttr.autocapitalize"
                  :autocorrect="field.inputAttr.autocorrect"
                  :pattern="field.inputAttr.pattern"
                  :required="field.required ? true : false"
                  @input="handleTouch($v.formFields[field.handle])"
                  @focus="handleFocus($v.formFields[field.handle], field.handle)"
                  @blur="handleBlur($v.formFields[field.handle])">
              </template>

              <template v-if="$v.formFields[field.handle]">
                <p v-if="$v.formFields[field.handle].$dirty && $v.formFields[field.handle].$error" class="has-error">
                  <span v-for="param in $v.formFields[field.handle].$params">
                    <span v-if="!$v.formFields[field.handle][param.type]">
                      <span v-if="param.type == 'minLength'">
                        {{ errorMessages[param.type].replace('0', param.min) }}
                      </span>
                      <span v-else-if="param.type == 'maxLength'">
                        {{ errorMessages[param.type].replace('0', param.max) }}
                      </span>
                      <span v-else>{{ errorMessages[param.type] }}</span>
                    </span>
                  </span>
                </p>
              </template>

              <div class="help-block" v-if="field.instructions && field.groupClass.includes('l_placeholder')" v-html="field.instructions"></div>

            </div>

          </template>

        </template>

      </div>
    </div>

  </form>
</div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios';
import { required, minLength, maxLength, email, sameAs } from 'vuelidate/lib/validators';
// import { postcode, phone, phoneAu } from '../utils/custom-validators.js';
var customValidators = [];
// customValidators['postcode'] = postcode;
import { page } from 'vue-analytics';
// import jQuery from 'jquery';
// import * as Scrll from '../utils/scroll.js';
const touchMap = new WeakMap();
export default {
  name: "Freeform",
  data() {
    return {
      formCustomClass: '',
      formLayout: [],
      formFields: {},
      formHoneypot: [],
      formTouched: false,
      formErrors: [],
      fieldErrors: [],
      validate: {
        'formFields': {}
      },
      errors: false,
      errorMessages: {
        'required': 'This field is required',
        'email': 'Please enter a valid email address',
        'postcode': 'Please enter a valid postcode',
        'phone': 'Please enter a valid phone number',
        'minLength': 'Must be more than 0 characters',
        'maxLength': 'Must be less than 0 characters'
      },
      isSubmitted: false,
      isSuccess: false,
      successMessage: '<h4>Success</h4><p>The form was submitted successfully.</p>',
      responseCustomClass: '',
      focusField: false,
      options: {}
    };
  },
  props: {
    formHandle: String,
    formHash: String,
    formReturnUrl: String,
    formJson: Object,
    formVars: Object,
    formPrefill: Object,
    formAttr: Object,
    formOptions: {
      type: Object,
      default: function () { return {} }
    }
  },
  mounted() {

    // init
    if (typeof this.formVars.successMessage !== 'undefined' && this.formVars.successMessage.length) {
      this.successMessage = this.formVars.successMessage;
    }
    this.formHoneypot = this.formVars.honeypot;

    // options
    this.options = this.formOptions;
    if (typeof this.options.labelStyle == 'undefined') {
      this.options.labelStyle = 'standard';
    }
    if (typeof this.options.submitButton == 'undefined') {
      this.options.submitButton = 'standard';
    }
    if (typeof this.options.responseStyle == 'undefined') {
      this.options.responseStyle = 'standard';
    }

    // response class
    this.responseCustomClass = 'l_' + this.options.responseStyle;

    // custom attr
    if (this.formAttr) {
      if (typeof this.formAttr.class !== 'undefined') {
        this.formCustomClass = this.formAttr.class;
      }
    }

    // custom row class
    var rowClass = 'form-row';
    if (typeof this.formJson.customAttributes !== 'undefined') {
      this.formJson.customAttributes.forEach( (attr) => {

        // custom classes
        if (attr.attribute == 'rowClass') {
          rowClass = attr.value;
        }

      });
    }

    // loop through form layout
    this.formJson.composer.layout[0].forEach( (row, index) => {

      var defaultCols = 12  / row.columns.length;
      var columns = [];
      var isHidden = true;

      row.columns.forEach( (col, index) => {

        var field = this.formJson.composer.properties[col];
        // console.log(field);

        // init
        field.cols = defaultCols;
        field.customClass = '';
        field.isCheckbox = false;
        field.conditional = [];
        field.conditionalField = '';
        field.conditionalValue = '';
        var conditionalField = '';
        var defaultRows = 1;
        var defaultDataMinRows = 1;

        // row class
        if (field.type !== 'hidden') {
          isHidden = false;
        }

        // required
        if (typeof field.required == 'undefined') {
          field.required = false;
        }

        // ensure inputAttributes is an array
        if (typeof field.inputAttributes == 'undefined') {
          field.inputAttributes = [];
        }

        // apply inputAttributes
        if (typeof field.inputAttributes !== 'undefined' && field.inputAttributes.length) {
          field.inputAttributes.forEach( (attr) => {

            // custom classes
            if (attr.attribute == 'class') {
              field.customClass += ' ' + attr.value;
            }

            // custom cols
            if (attr.attribute == 'col') {
              field.cols = attr.value;
            }

            // conditional fields
            if (attr.attribute == 'conditionalValue' && conditionalField.length) {
              field.conditional[conditionalField] = attr.value;
              field.conditionalValue = attr.value;
            } else if (attr.attribute == 'conditionalField') {
              conditionalField = attr.value;
              field.conditional[conditionalField] = true;
              field.conditionalField = conditionalField;
            } else {
              conditionalField = '';
            }

          });
        }

        // column class
        field.groupClass = 'form-group';
        field.groupClass += ' col-' + field.cols;
        field.groupClass += field.customClass;

        // position
        if (typeof field.position !== 'undefined') {
          field.groupClass += ' l_text-' + field.position;
        }

        // required
        if (field.required) {
          field.groupClass += ' l_required';
        }
        // groupClass =+ ' ' + form.customAttributes.columnClass

        // control class
        field.controlClass = 'form-control';

        // input class
        field.inputClass = '';
        if (typeof field.inputAttr == 'undefined') {
          field.inputAttr = {};
        }

        // label class
        field.labelClass = '';

        // textarea auto expand
        if (field.type == 'textarea') {
          field.inputClass += ' l_auto-expand';
          field.inputAttr = {
            'rows': defaultRows,
            'dataMinRows': defaultDataMinRows
          }
        }

        // computed type
        field.computedType = '';
        if (field.type == 'checkbox_group' || field.type == 'radio_group') {
          field.computedType = 'option_group';
        }


        // single checkbox
        if (field.type == 'checkbox') {
          field.labelClass += 'checkbox-stacked';
          if (field.required) {
            field.labelClass += ' l_required';
          }
        }

        // checkbox and radio option type
        field.optionType = '';
        field.name = field.handle;
        if (field.type.includes('checkbox')) {
          field.optionType = 'checkbox';
          if (field.type.includes('group')) {
            field.name = field.handle + '[]';
          }
        } else if (field.type.includes('radio')) {
          field.optionType = 'radio';
        }

        // checkbox and radio - stacked or inline
        field.optionClass = 'stacked'
        if (typeof field.oneLine !== 'undefined' && field.oneLine) {
          field.optionClass = 'inline';
        }

        // file upload
        if (field.type == 'file') {
          field.name = field.handle + '[]';
          field.inputClass += ' file-upload';
        }

        // placeholders
        if (((typeof field.placeholder !== 'undefined' && field.placeholder.length == 0) || typeof field.placeholder == 'undefined') && (field.type == 'text' || field.type == 'textarea' || field.type == 'number' || field.type == 'email' || field.type == 'select' || field.type == 'dynamic_recipients')) {
          field.groupClass += ' l_placeholder';
        }

        // html5 fieldtypes
        if (typeof field.handle !== 'undefined') {
          var fieldNameLower = field.handle.toLowerCase();
          if (fieldNameLower.includes('phone') || fieldNameLower.includes('mobile') || fieldNameLower.includes('fax')) {
            field.type = 'tel';
          }
          if (fieldNameLower.includes('email')) {
            field.type = 'email';
            field.inputAttr['autocapitalize'] = 'off';
            field.inputAttr['autocorrect'] = 'off';
          }
          if (fieldNameLower.includes('address')) {
            field.inputAttr['autocorrect'] = 'off';
          }
          if (fieldNameLower.includes('name')) {
            field.inputAttr['autocorrect'] = 'off';
          }
          if (fieldNameLower.includes('postcode')) {
            field.type = 'number';
          }
          if (typeof field.inputAttributes !== 'undefined') {
            field.inputAttributes.forEach( (attr) => {

              if (attr.attribute == 'type') {
                field.type = attr.value;
              }

              if (attr.attribute == 'pattern') {
                field.inputAttr['pattern'] = attr.value;
              }

            });
          }
        }

        // set default model
        if (field.type == 'checkbox_group') {
          Vue.set(this.formFields, [field.handle], []);
          if (typeof field.values !== 'undefined' && field.values.length) {
            Vue.set(this.formFields, [field.handle], field.values);
          }
        } else {
          Vue.set(this.formFields, [field.handle], '');
          if (typeof field.value !== 'undefined' && field.value.length) {
            if ((field.type == 'checkbox' && field.checkedByDefault) || field.type !== 'checkbox') {
              Vue.set(this.formFields, [field.handle], field.value);
            }
          } else if (typeof this.formPrefill  === 'object' && typeof this.formPrefill[field.handle] !== 'undefined') {
            Vue.set(this.formFields, [field.handle], this.formPrefill[field.handle]);
          }
        }

        columns.push(field);

      });

      var thisRowClass = rowClass;
      if (isHidden) {
        thisRowClass += ' s_hidden';
      }

      this.formLayout.push({
        id: row.id,
        columns: columns,
        cols: row.columns.length,
        rowClass: rowClass
      });

    });

  },
  methods: {

    formSubmit(event) {

      this.isSubmitted = true;

      event.preventDefault();

      this.$v.$touch();

      this.formTouched = !this.$v.formFields.$anyDirty;
      this.errors = this.$v.formFields.$anyError;
      if (this.errors === false && this.formTouched === false) {

        // console.log('form submitted');

        var form = event.target;
        var data = new FormData(form);

        var method = form.getAttribute("method");
        var action = form.getAttribute("action");

        // console.log( data);

        axios({
          url: action ? action : window.location.href,
          method: method ? method : "post",
          data: data,
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            HTTP_X_REQUESTED_WITH: "XMLHttpRequest",
          },
        })
        .then((responseObject) => {
          var response = responseObject.data;

          if (response.success && response.finished) {
            this.isSuccess = true;
            // form.reset();
            console.log("Form posted successfully");
            console.log(response.success);
            console.log(response);
          } else if (response.errors || response.formErrors) {
            this.isSubmitted = false;
            this.isSuccess = false;
            console.log("Form failed to submit");
            console.log("Field Errors", response.errors);
            console.log("Form Errors", response.formErrors);
          }

          // scroll to top
          // Scrll.toElement(jQuery('#form_' + this.formHandle));

          // virtual pageview for return URL for google analytics
          if (this.formReturnUrl.length) {
            // page(this.formReturnUrl);
            window.location.href = this.formReturnUrl;
          }

          // update the Honeypot field if using JS enhancement
          if (response.honeypot) {
            this.formHoneypot = response.honeypot;
          }
        })
        .catch((error) => {
          console.error(error);
          this.isSubmitted = false;
          this.isSuccess = false;

        });

      } else {
        this.isSubmitted = false;
        this.isSuccess = false;

        // scroll to top
        setTimeout(function(){
          // Scrll.toElement(jQuery('.form-group.has-error'));
        }, 10);

      }

    },

    handleTouch($v) {
      if ($v.$initialTouch) {
        $v.$touch();
      }
    },

    handleBlur($v) {
      this.focusField = false;
      $v.$initialTouch = true;
      this.handleTouch($v);
    },

    handleFocus($v, handle) {
      this.focusField = handle;
    }

  },
  validations: function() {
    var v = {};
    v['formFields'] = {};

    this.formJson.composer.layout[0].forEach( (row, index) => {

      var defaultCols = 12  / row.columns.length;
      var columns = [];

      row.columns.forEach( (col, index) => {

        var field = this.formJson.composer.properties[col];

        v.formFields[field.handle] = {};

        // required
        if (typeof field.required !== 'undefined' && field.required) {
          v.formFields[field.handle]['required'] = required;
        }

        // email
        if (field.type == 'email') {
          v.formFields[field.handle]['email'] = email;
        }

        // max length
        if (typeof field.maxLength !== 'undefined') {
          v.formFields[field.handle]['maxLength'] = maxLength(field.maxLength);
        }

        // attributes loop
        if (typeof field.inputAttributes !== 'undefined' && field.inputAttributes.length) {
          field.inputAttributes.forEach( (attr) => {

            // min length
            if (attr.attribute == 'minLength') {
              v.formFields[field.handle]['minLength'] = minLength(attr.value);
            }

            // max length
            if (attr.attribute == 'maxLength') {
              v.formFields[field.handle]['maxLength'] = maxLength(attr.value);
            }

          });
        }

        // TODO: more validations?
        // TODO: dynamic validation variable

      });

    });

    return v;
  },
};
</script>

<style scoped>
</style>

<!--
EXAMPLE:
{% set formHandle = 'contact' %}
{% set form = craft.freeform.form(formHandle) %}
<Freeform
  form-handle="{{ form.handle }}"
  form-hash="{{ form.getHash() }}"
  form-return-url="{{ form.returnUrl }}"
  :form-json="{{ craft.vueFreeformModule.renderFormJson(formHandle) }}"
  :form-vars="{{ craft.vueFreeformModule.renderFormVars(formHandle) }}">
</Freeform>
 -->