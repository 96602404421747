<template>
	<div class="wardrobe-product-colors">
		<div class="wardrobe-product-colors-heading">
			<strong>Framed Colours: <span class="selected-color" v-html="selectedColor.name"></span></strong>
		</div>
		<div class="wardrobe-product-colors-holder">
			<span
				v-for="(color, index) in colors"
				v-bind:key="index"
				class="wardrobe-color"
				v-bind:style="{ backgroundColor: color.color }"
				v-on:click="changeColor(color)"
			></span>
		</div>
	</div>
</template>

<script>
import Vue from "vue";
export default {
	name: "WardrobeColors",
	data() {
		return {
			selectedColor: this.colors[0]
		};
	},
	props: {
		colors: Array
	},
	methods: {
		changeColor: function(color) {
			this.selectedColor = color;
		}
	}
};
</script>

<style scoped>
.selected-color {
	color: #00a1e4;
}
.wardrobe-color {
	display: inline-block;
	width: 36px;
	height: 36px;
	border-radius: 100%;
	border: 3px solid white;
}
</style>