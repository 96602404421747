
import inView from 'in-view';
import 'jquery-touchswipe';
var imagesLoaded = require('imagesloaded');
imagesLoaded.makeJQueryPlugin( $ );

//* slider =================================================== */

var sliders = {};

class Slider {

  constructor(options) {

    // options
    this.id = options.id;
    this.$slider = $('[data-sliderId="' + this.id + '"]');
    this.current = options.current;
    this.next = this.getNext();
    this.prev = this.getPrev();
    this.total = options.total;
    this.speed = options.speed;
    this.goFunc = false;
    this.waiting = false;
    this.screenWidth = $(window).width();
    this.screenWidthHalf = this.ScreenWidth / 2;
    this.offset = false;
    this.moving = false;
    this.type = 'zoom';
    this.show = 1;

    // init
    var $slider = this.$slider;

    // count slides
    this.total = $slider.find('.slider-slide').length;

    // set slide ids
    var slideId = 0;
    var hasTitles = false;
    var hasSubtitles = false;
    $slider.find('.slider-slide').each(function(){

      // set slide id
      slideId ++;
      $(this).attr('data-id', slideId);

      // check title and subtitles
      if ($(this).attr('data-title').length) {
        hasTitles = true;
      }
      if ($(this).attr('data-subtitle').length) {
        hasSubtitles = true;
      }

    });

    // remove titles if not needed
    if (!hasTitles) {
      $slider.find('.slider-title').remove();
    }
    if (!hasSubtitles) {
      $slider.find('.slider-subtitle').remove();
    }

    // resize slides
    this.resize();

    // create offsets
    var $sliderOffsetRight = $slider.find('.slider-track-main').clone().addClass('slider-offset-right').removeClass('slider-track-main').css('left', '100%');
    $slider.find('.slider-tracks').append($sliderOffsetRight);

    var $sliderOffsetLeft = $slider.find('.slider-track-main').clone().addClass('slider-offset-left').removeClass('slider-track-main').css('left', '-100%');
    $slider.find('.slider-tracks').append($sliderOffsetLeft);

    // create nav
    for (var i=1;i<=this.total;i++) {
      $slider.find('.slider-nav').append('<button class="slider-dot" data-id="' + i + '" data-sliderId="' + this.id + '"></button>');
    }

    // start
    $slider.addClass('s_init');
    this.start();

    // init swipe
    $slider.swipe({
      swipe:(event, direction, distance, duration, fingerCount) => {
        // console.log(direction);
        if (direction == 'right') {
          this.prevSlide();
        } else if (direction == 'left') {
          this.nextSlide();
        }
      }
    });
  }

  resize() {

    this.clipWings();

    var $slider = this.$slider;

    // reset screen variables
    this.screenWidth = $(window).width();
    this.screenWidthHalf = this.screenWidth / 2;

    // resize images responsively
    var imageWidth = false;
    var imageHeight = false;
    if ($(window).width() > 1279) {
      imageHeight = 600;
    } else if ($(window).width() > 768) {
      imageHeight = 514;
    } else if ($(window).width() > 600) {
      imageHeight = 300;
    // } else if (breakpointMin('baby')) {
      // imageWidth = $('.row-inner').width() - 60;
    } else {
      imageHeight = 200;
      // imageWidth = $(window).width();
    }

    // apply image resizing
    if (imageWidth && imageHeight) {
      $slider.find('.slider-image').height(imageHeight);
      $slider.find('.slider-image').width(imageWidth);
      $slider.find('.slider-image img').height(imageHeight);
      $slider.find('.slider-image img').width(imageWidth);
    } else if (imageHeight) {
      $slider.find('.slider-image').height(imageHeight);
      $slider.find('.slider-image').width('auto');
      $slider.find('.slider-image img').height(imageHeight);
      $slider.find('.slider-image img').width('auto');
    } else if (imageWidth) {
      $slider.find('.slider-image').height('auto');
      $slider.find('.slider-image').width(imageWidth);
      $slider.find('.slider-image img').height('auto');
      $slider.find('.slider-image img').width(imageWidth);
    }

    var slidesAllWidth = 0;
    $slider.find('.slider-track-main .slider-slide').each(function(){
      // console.log($(this).outerWidth());
      // $(this).width($(this).find('.slider-image').outerWidth());
      slidesAllWidth += $(this).outerWidth() + parseInt($(this).css('marginLeft')) + parseInt($(this).css('marginRight'));
    });
    // slidesAllWidth += 100;
    // console.log('width =');
    // console.log(slidesAllWidth);
    $slider.find('.slider-track, .slider-tracks').width(slidesAllWidth);
    $slider.find('.slider-offset-right').css('left', '100%');
    $slider.find('.slider-offset-left').css('left', '-100%');

  }

  start() {
    var sliderId = this.id;
    this.goFunc = setInterval(() => {
      // sliders[sliderId].nextSlide();
      // this.nextSlide();
    }, this.speed);
    this.updateSlide();
  }

  restartTimer() {
    var sliderId = this.id;
    clearInterval(this.goFunc);
    this.goFunc = setInterval(() => {
      // sliders[sliderId].nextSlide();
      // this.nextSlide();
    }, this.speed);
  }

  nextSlide() {
    // console.log('next');
    // console.log(this.waiting);
    if (!this.waiting) {
      this.current = this.getNextSlideId();
      if (this.current == 1) {
        this.offset = 'right';
      }
      this.updateSlide();
    }
  }

  prevSlide() {
    // console.log('prev');
    // console.log(this.waiting);
    if (!this.waiting) {
      this.current = this.getPrevSlideId();
      if (this.current == this.total) {
        this.offset = 'left';
      }
      this.updateSlide();
    }
  }

  getNextSlideId() {
    var slideId = this.current + 1;
    if (slideId > this.total) {
      slideId = 1;
    }
    return slideId;
  }

  getPrevSlideId() {
    var slideId = this.current - 1;
    if (slideId < 1) {
      slideId = this.total;
    }
    return slideId;
  }

  setSlide(slideId) {

    var nextId = this.getNextSlideId();
    var prevId = this.getPrevSlideId();

    if (slideId == nextId) {
      this.nextSlide();
    } else if (slideId == prevId) {
      this.prevSlide();
    } else {
      if (!this.waiting) {
        this.current = slideId;
        this.updateSlide();
      }
    }
    // if (slideId > this.current) {
    //   this.offset = 'right';
    // } else {
    //   this.offset = 'left';
    // }
  }

  clipWings() {

    // var $slider = this.$slider;

    // var maxPad = 50;
    // $slider.find('.slider-track .slider-slide').each(function(){

    //   if (!$(this).hasClass('s_active')) {
    //     var tempWidth = $(this).find('.slider-image').outerWidth();
    //     var tempPad = maxPad - (tempWidth * .15  / 2);
    //     if (tempPad < 0) {
    //       tempPad = 0;
    //     }
    //     console.log('tempPad: ' + tempPad);
    //     $(this).css('padding-left', tempPad + 'px');
    //     $(this).css('padding-right', tempPad + 'px');
    //   } else {
    //     $(this).css('padding-left', maxPad + 'px');
    //     $(this).css('padding-right', maxPad + 'px');
    //   }

    // });

  }

  updateSlide() {
    var sliderId = this.id;
    var $slider = this.$slider;
    // console.log($slider);
    var $slide = $slider.find('.slider-slide[data-id="' + this.current + '"]');

    this.waiting = true;

    $slider.find('.slider-slide.s_active').removeClass('s_active s_usable');
    $slide.addClass('s_active');

    this.clipWings();

    // this.resize();

    // update titles
    var slideTitle = $slide.attr('data-title');
    if (slideTitle.length == 0) {
      slideTitle = '&nbsp;';
    }
    // if (slideTitle.length) {
      $slider.find('.slider-title').removeClass('s_active');
      $slider.find('.slider-title').html(slideTitle);
      setTimeout(() => {
        $slider.find('.slider-title').addClass('s_active');
      }, 300);
    // }

    var slideSubtitle = $slide.attr('data-subtitle');
    if (slideSubtitle.length == 0) {
      slideSubtitle = '&nbsp;';
    }
    // if (slideSubtitle.length) {
      $slider.find('.slider-subtitle').removeClass('s_active');
      $slider.find('.slider-subtitle').html(slideSubtitle);
      setTimeout(() => {
        $slider.find('.slider-subtitle').addClass('s_active');
      }, 300);
    // }


    // setTimeout(function(){
    //   sliders[sliderId].activateSlide();
    // }, 1);
    // setTimeout(function(){
    //   sliders[sliderId].hideSlide();
    // }, 1000);

    // next + prev
    this.updateNextPrev();
    $slider.find('.slider-slide.s_next').removeClass('s_next');
    $slider.find('.slider-slide.s_prev').removeClass('s_prev');
    $slider.find('.slider-slide[data-id="' + this.next + '"]').addClass('s_next');
    $slider.find('.slider-slide[data-id="' + this.prev + '"]').addClass('s_prev');

    // update height of slider
    this.outerHeight = $slide.outerHeight();
    $slider.find('.slider-container').height(this.outerHeight);

    // if offset, set extra value
    var sliderPositionExtra = 0;
    if (this.offset == 'right') {
      sliderPositionExtra = $slider.find('.slider-track-main').outerWidth();
    } else if (this.offset == 'left') {
      sliderPositionExtra = $slider.find('.slider-track-main').outerWidth() * -1;
    }

    // calculate offset
    var sliderPosition = ( ( $slide.position().left + ( $slide.outerWidth() / 2 ) ) - this.screenWidthHalf + sliderPositionExtra ) * -1;
    // console.log(sliderPosition);

    // move slider
    $slider.find('.slider-tracks').animate({ 'left': sliderPosition }, () => {
      if (this.offset == 'right' || this.offset == 'left') {
        var offsetReset = sliderPosition + sliderPositionExtra;
        $slider.find('.slider-tracks').css('left', offsetReset);
        this.offset = false;
      }
      var activeSlideEdge = $slide.outerWidth() + ((this.screenWidth - $slide.outerWidth()) / 2);
      $slider.find('.slider-invisibtn.l_next').css('left', activeSlideEdge + 'px');
      $slider.find('.slider-invisibtn.l_prev').css('right', activeSlideEdge + 'px');
      setTimeout(() => {
        // console.log($slider);
        $slider.find('.slider-slide.s_active').addClass('s_usable');
      }, 150);
      this.waiting = false;
    });

    // update dots
    $slider.find('.slider-dot').removeClass('s_active');
    $slider.find('.slider-dot[data-id="' + this.current + '"]').addClass('s_active');

  }

  getNext() {
    this.next = this.current + 1;
    if (this.next > this.total) { this.next = 1; }
  }

  getPrev() {
    this.prev = this.current - 1;
    if (this.prev < 1) { this.prev = this.total; }
  }

  updateNextPrev() {
    this.getNext();
    this.getPrev();
  }

  // activateSlide() {
  //   var $slider = $('[data-sliderId="' + this.id + '"]');
  //   $slider.find('.slider-slide.s_next').addClass('s_active').removeClass('s_next');
  //   $('.slider-slidecomp.s_next').addClass('s_active').removeClass('s_next');
  // }

  // hideSlide() {
  //   var $slider = $('[data-sliderId="' + this.id + '"]');
  //   $slider.find('.slider-slide.s_hide').removeClass('s_hide s_transitions s_active').css('z-index', 0);
  //   $('.slider-slidecomp.s_hide').removeClass('s_hide s_transitions s_active').css('z-index', 0);
  //   this.waiting = false;
  // }
}


// TODO load after images loaded
var sliderNum = 0;
function sliderInit($el) {

  $el.each(function(){

    $(this).imagesLoaded(() => {

      if ($(this).find('.slider-slide').length > 1) {

        sliderNum++;

        var sliderId = 'slider' + sliderNum;

        $(this).attr('data-sliderId', sliderId);
        var options = {
          id: sliderId,
          current: 2,
          total: $(this).find('.slider-slide').length,
          speed: 4000
        };
        setTimeout(() => {
          sliders[sliderId] = new Slider(options);
        }, 50);
        setTimeout(() => {
          sliders[sliderId].resize();
        }, 100);
      } else {
        $(this).find('.slider-slide').addClass('s_active');
        // $('.slider-slidecomp').addClass('s_active');
        $('.slider-controls').remove();
      }

    });

  });

};

inView('.slider').on('enter', el => {

  if (typeof $(el).attr('data-init') == 'undefined') {

    $(el).find('.slider-image').each(function(){

      console.log($(this).attr('data-src'));
      if ($(this).attr('data-src')) {
        $(this).attr('src', $(this).attr('data-src'));
      }

    });

    sliderInit($(el));

  }

  $(el).attr('data-init', true);

});

$(document).on('click','[data-action="nextSlide"]',function(){
  var sliderId = $(this).parents('.slider').attr('data-sliderId');
  sliders[sliderId].restartTimer();
  sliders[sliderId].nextSlide();
  return false;
});

$(document).on('click','[data-action="prevSlide"]',function(){
  var sliderId = $(this).parents('.slider').attr('data-sliderId');
  sliders[sliderId].restartTimer();
  sliders[sliderId].prevSlide();
  return false;
});


$(window).resize(function(){

  const keys = Object.keys(sliders)
  for (const key of keys) {
    setTimeout(() => {
      sliders[key].resize();
      sliders[key].updateSlide();
    }, 100);
  }

});

$(document).keydown(function(e) {

  // left
  if (e.keyCode == 37) {
    sliders['slider1'].restartTimer();
    sliders['slider1'].prevSlide();
  }

  // right
  if (e.keyCode == 39) {
    sliders['slider1'].restartTimer();
    sliders['slider1'].nextSlide();
  }

});

$(document).on('click','.slider-dot',function(){
  var sliderId = $(this).attr('data-sliderId');
  var slideId = $(this).attr('data-id');
  sliders[sliderId].restartTimer();
  sliders[sliderId].setSlide(slideId);
});