<template>
<div>
    <h1>Vue Example Component</h1>
</div>
</template>

<script>
export default {
  name: "ExampleComponent",
  data() {
    return {
      
    };
  },
  methods: { 
  },
};
</script>

<style scoped>
</style>